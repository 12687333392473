
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmProgressSteps from '@/components/shared/progressSteps/TmProgressSteps.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import type { StepperStep } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmProgressSteps,
    TmScrollbar,
  },
  props: {
    steps: {
      type: Array as PropType<StepperStep[]>,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    stepsSize: {
      type: String,
      default: '900px',
    },
    size: {
      type: String as PropType<'medium' | 'large'>,
      default: 'medium',
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
  setup(props, { slots }) {
    const hasTextSlot = !!slots.text
    const hasSubtitleSlot = !!slots.subtitle

    const hasAfterBoxSlot = !!slots['after-box']

    return {
      hasTextSlot,
      hasAfterBoxSlot,
      hasSubtitleSlot,
    }
  },
})
