import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-319c27a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-step__main-container" }
const _hoisted_2 = {
  key: 0,
  class: "hero-24 lh-30 text-center mb-2"
}
const _hoisted_3 = {
  key: 1,
  class: "subhead-regular-16 text-center mb-6 distinct--text lh-20"
}
const _hoisted_4 = { class: "page-step__content-container" }
const _hoisted_5 = { class: "page-box" }
const _hoisted_6 = {
  key: 0,
  class: "subhead-regular-16 distinct--text mt-4 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_progress_steps = _resolveComponent("tm-progress-steps")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["page-step", `page-step__${_ctx.size}`])
  }, [
    _createVNode(_component_tm_scrollbar, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (_ctx.subtitle || _ctx.hasSubtitleSlot)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "subtitle", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
                ], true)
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "before-steps", {}, undefined, true),
          _createVNode(_component_tm_progress_steps, {
            class: "gt-sm",
            "current-step-index": _ctx.currentStepIndex,
            steps: _ctx.steps,
            style: _normalizeStyle({
            'max-width': _ctx.stepsSize,
          })
          }, null, 8, ["current-step-index", "steps", "style"]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "before-content", {}, undefined, true),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ]),
            (_ctx.hasAfterBoxSlot)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "after-box", {}, undefined, true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 3
    })
  ], 2))
}